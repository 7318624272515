import React from 'react'
import Flex from '../Flex/Flex'
import { Spinner } from '@chakra-ui/react'

export const CenterSpinner = () => (
  <Flex
    justifyContent="center"
    w={'100%'}
    minHeight={200}
    alignItems="center"
    flexDirection="column"
    testId="loading-spinner"
  >
    <Spinner emptyColor="transparent" color="indigo.100" />
  </Flex>
)
