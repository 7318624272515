import { Icon, IconProps } from '@chakra-ui/react'

export const EvrSquare = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 20h-16c-0.827 0-1.5-0.673-1.5-1.5v-16c0-0.827 0.673-1.5 1.5-1.5h16c0.827 0 1.5 0.673 1.5 1.5v16c0 0.827-0.673 1.5-1.5 1.5zM1.5 2c-0.276 0-0.5 0.224-0.5 0.5v16c0 0.276 0.224 0.5 0.5 0.5h16c0.276 0 0.5-0.224 0.5-0.5v-16c0-0.276-0.224-0.5-0.5-0.5h-16z"
      fill="currentColor"
    ></path>
  </Icon>
)
