import { ModalHeader as Base, BoxProps as Props } from '@chakra-ui/react'

export interface BoxProps extends Props {
  /**
   *
   * testId is used in SAT/e2e as element selector
   *
   * @type {string}
   * @memberof BoxProps
   */
  testId?: string
}

export function ModalHeader(props: BoxProps) {
  return <Base 
        {...props} 
        borderBottomColor={'gray.60'} 
        borderBottomWidth={'1px'} 
        backgroundColor="gray.50"
        borderTopRightRadius="3px"
        borderTopLeftRadius="3px"
        px="20px"
        py="15px"
    />
}
export default ModalHeader
