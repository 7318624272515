import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

const CustomDatePicker = ({ defaultDate, onChange }) => {
  const [startDate, setStartDate] = useState(defaultDate ? new Date(defaultDate * 1000) : new Date())

  useEffect(() => {
    setStartDate(defaultDate ? new Date(defaultDate * 1000) : new Date())
  }, [defaultDate])

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        if (date !== null) {
          const adjustedDate = new Date(date)
          adjustedDate.setHours(0, 0, 0, 0) // Ignore the time part
          setStartDate(adjustedDate)
          onChange(adjustedDate.getTime() / 1000) // Convert milliseconds to seconds
        }
      }}
      dateFormat="yyyy-MM-dd"
      popperPlacement="top"
    />
  )
}

export default CustomDatePicker
