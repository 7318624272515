import { QuestionIcon } from '@chakra-ui/icons'
import { FormControlProps, InputAddonProps, InputProps } from '@chakra-ui/react'
import { is } from 'ramda'
import React from 'react'
import {
  Controller,
  FieldError as BaseFieldError,
  FieldPath,
  FieldValues,
  Message,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form'
import { Trans } from 'react-i18next'
import {
  Checkbox,
  CheckboxProps,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Select,
  SelectProps,
  Stack,
  Textarea,
  TextareaProps,
} from '../'
import { createTestId } from '../../utils'
import { BASE_STYLE, BASE_THEME } from '../AutocompletSelect/AutocompletSelect'
import Tooltip from '../Tooltip'
import { ErrorWrapper } from './ErrorWrapper'
import Input from './Input'
import CustomDatePicker from '../DatePicker/DatePicker'

type ValidationRules = RegisterOptions<FieldValues, FieldPath<FieldValues>>

interface BaseProps {
  testId?: string
  type: string
  tooltip?: string
  name: string
  label: string
  helperText?: React.ReactElement<unknown>
  labelRight?: React.ReactElement<unknown>
  validationOptions?: ValidationRules
  enableFormLable?: boolean
  inputGroup?: {
    inputRightAddon?: React.ReactElement<InputAddonProps>
    inputLeftAddon?: React.ReactElement<InputAddonProps>
  }
  defaultDate?: string | number // Unix timestamp in seconds
  onChange?: () => void
}

interface BaseInputWrapperProps extends Omit<BaseProps, 'type'> {
  error: FieldError | undefined
  messages: string[]
  isRequired?: boolean
}

type ControlProps = BaseProps & InputProps & SelectProps & CheckboxProps & TextareaProps

export function BaseInputWrapper({
  label,
  labelRight,
  messages = [],
  children,
  isRequired,
  error,
  name,
  enableFormLable = true,
  helperText,
  inputGroup,
  testId = name ?? 'form-control',
  defaultDate,
  ...props
}: React.PropsWithChildren<BaseInputWrapperProps & FormControlProps>) {
  const inputGroupWrapper = (
    <InputGroup>
      {children}
      {inputGroup?.inputLeftAddon}
      {inputGroup?.inputRightAddon}
    </InputGroup>
  )

  const body = inputGroup ? inputGroupWrapper : children

  return (
    <FormControl
      data-testid={'form-control'}
      w={'100%'}
      isInvalid={!!error}
      isRequired={isRequired}
      {...props}
    >
      {enableFormLable && (
        <Flex justifyContent="space-between">
          <Stack
            alignItems="start"
            color={props.color}
            isInline
            spacing={2}
            shouldWrapChildren
            flexGrow={1}
            as={FormLabel}
            //@ts-ignore
            htmlFor={name}
          >
            <Trans>{label}</Trans>

            {props.tooltip && (
              <Tooltip label={props.tooltip} aria-label={props.tooltip} placement="top">
                <QuestionIcon color="#d7d7d9" name="question" boxSize="17px" />
              </Tooltip>
            )}
          </Stack>
          {labelRight}
        </Flex>
      )}
      {body}
      {helperText}
      <ErrorWrapper label={label} name={name} messages={messages} />
    </FormControl>
  )
}

interface ApiLayerFieldError {
  message?: Message | Message[]
}

type FieldError = ApiLayerFieldError & BaseFieldError

function createErrorMessages(error: FieldError | undefined) {
  let message: string[] = []

  if (error) {
    if (error.message) {
      if (is(Array, error.message)) {
        message = error.message as string[]
      } else {
        message = [error.message as string]
      }
    }

    if (error.types) {
      if (is(Array, error.types.message)) {
        message = error.types.message as string[]
      } else {
        message = [error.types.message as string]
      }
    }
  }
  return message
}

export function Control({
  label,
  labelRight,
  validationOptions,
  mb,
  inputGroup,
  testId: baseTestId,
  children,
  ...props
}: ControlProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()
  const isRequired = props.isRequired || !!validationOptions?.required
  const options = { ...validationOptions }
  const testId = baseTestId ?? createTestId(label, props.name)

  if (isRequired && !validationOptions?.required) {
    //@ts-ignore
    options.required = ' is required'
  }

  //@ts-ignore
  const error: FieldError | undefined = errors[props.name]

  const message = createErrorMessages(error)

  if (props.type === 'textarea') {
    return (
      <BaseInputWrapper
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        color={props.color}
        messages={message}
        isRequired={isRequired}
        helperText={props.helperText}
        tooltip={props.tooltip}
      >
        <Textarea
          testId={testId}
          _hover={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          _focus={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          id={props.name}
          aria-label={label}
          isRequired={isRequired}
          {...props}
          {...register(props.name, {
            ...options,
          })}
        />
      </BaseInputWrapper>
    )
  }

  if (props.type === 'select' || props.type === 'autocomplete-select') {
    const { name, ...selectProps } = props
    const isMobile = window.matchMedia('(max-width: 30em)')
    const border = isMobile.matches
      ? {
          borderWidth: 0,
        }
      : {
          borderWidth: 2,
          borderColor: message.length > 0 ? 'red.100' : 'rgb(215, 215, 217)',
          boxShadow: message.length > 0 ? '0 0 0 1px red.50' : 'unset',
        }

    return (
      <BaseInputWrapper
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        messages={message}
        isRequired={isRequired}
        tooltip={props.tooltip}
      >
        <Controller
          data-testid="control-select-container"
          name={name}
          rules={options}
          render={({ field }) => (
            <Select
              id={name}
              data-testid={'control-select'}
              classNamePrefix="control-select"
              testId={testId}
              aria-label={label}
              border={border}
              theme={props.type === 'autocomplete-select' ? BASE_THEME : undefined}
              styles={props.type === 'autocomplete-select' ? BASE_STYLE : undefined}
              menuPlacement="auto"
              {...field}
              {...selectProps}
            />
          )}
          control={control}
        />
      </BaseInputWrapper>
    )
  }

  if (props.type === 'checkbox') {
    return (
      <BaseInputWrapper
        enableFormLable={false}
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        messages={message}
        inputGroup={inputGroup}
        isRequired={isRequired}
      >
        <Controller
          name={props.name}
          rules={options}
          render={({ field }) => (
            <Checkbox
              testId={testId ?? props.name}
              isRequired={isRequired}
              borderWidth={0}
              aria-describedby={label}
              {...props}
              {...field}
            >
              <Flex display="flex" flexDirection="row" alignItems="center">
                <Trans>{label}</Trans>
                {props.tooltip && (
                  <Tooltip label={props.tooltip} aria-label={props.tooltip} placement="top">
                    <QuestionIcon
                      color="gray.60"
                      name="question"
                      boxSize="17px"
                      marginLeft="10px"
                    />
                  </Tooltip>
                )}
                {children}
              </Flex>
            </Checkbox>
          )}
          control={control}
        />
      </BaseInputWrapper>
    )
  }

  if (props.type === 'text') {
    return (
      <BaseInputWrapper
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        color={props.color}
        messages={message}
        inputGroup={inputGroup}
        isRequired={isRequired}
        helperText={props.helperText}
        tooltip={props.tooltip}
      >
        <Input
          testId={testId}
          color={'navy.100 !important'}
          _hover={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          _focus={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          id={props.name}
          aria-label={label}
          isRequired={isRequired}
          {...props}
          {...register(props.name, {
            ...options,
          })}
        />
      </BaseInputWrapper>
    )
  }

  if (props.type === 'password') {
    return (
      <BaseInputWrapper
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        color={props.color}
        messages={message}
        inputGroup={inputGroup}
        isRequired={isRequired}
        helperText={props.helperText}
        tooltip={props.tooltip}
      >
        <Input
          testId={testId}
          color={'navy.100 !important'}
          _hover={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          _focus={{
            borderColor: 'indigo.100',
            boxShadow: 'unset',
          }}
          id={props.name}
          aria-label={label}
          isRequired={isRequired}
          {...props}
          {...register(props.name, {
            ...options,
          })}
        />
      </BaseInputWrapper>
    )
  }

  if (props.type === 'datepicker') {
    return (
      <BaseInputWrapper
        enableFormLable={true}
        label={label}
        labelRight={labelRight}
        name={props.name}
        error={error}
        mb={mb}
        messages={message}
        inputGroup={inputGroup}
        isRequired={isRequired}
      >
        <Controller
          name={props.name}
          rules={options}
          render={({ field }) => (
            <CustomDatePicker
              defaultDate={field.value}
              onChange={(date) => field.onChange(date)}
            />
          )}
          control={control}
        />
      </BaseInputWrapper>
    )
  }

  return null
}
