import { ModalBody as Base, BoxProps as Props } from '@chakra-ui/react'

export interface BoxProps extends Props {
  /**
   *
   * testId is used in SAT/e2e as element selector
   *
   * @type {string}
   * @memberof BoxProps
   */
  testId?: string
}

export function ModalBody(props: BoxProps) {
  return <Base 
    {...props} 
    py="15px" 
    px="20px" 
    color="navy.100"
    />
}
export default ModalBody
