import React from 'react'
import { Box as Base, BoxProps as Props, forwardRef } from '@chakra-ui/react'

export interface BoxProps extends Props {
  /**
   *
   * testId is used in SAT/e2e as element selector
   *
   * @type {string}
   * @memberof BoxProps
   */
  testId?: string
}

export const Box = forwardRef(({ testId, ...props }: BoxProps, ref: React.Ref<HTMLDivElement>) => {
  return <Base ref={ref} data-testid={testId} {...props} />
})

export default Box
