import { Icon, IconProps } from '@chakra-ui/react'

export const EvrPlus = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M18.5 10h-8.5v-8.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v8.5h-8.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.5v8.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-8.5h8.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z"
      fill="currentColor"
    ></path>
  </Icon>
)
