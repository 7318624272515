import { ThemeProvider, useToken } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import React from 'react'
import { theme } from '../theme'
import { cssReset } from './cssReset'

const CSSReset = (_props: unknown) => {
  const [borderColor, placeholderColor] = useToken('colors', [
    'gray.200',
    'gray.400',
    'gray.800',
  ])

  const configCSS = css`
    /**
      * Allow adding a border to an element by just adding a border-width.
      */

    *,
    *::before,
    *::after {
      border-width: 0;
      border-style: solid;
      border-color: ${borderColor};
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: ${placeholderColor};
    }

    input::-ms-input-placeholder,
    textarea::-ms-input-placeholder {
      color: ${placeholderColor};
    }

    input::placeholder,
    textarea::placeholder {
      color: ${placeholderColor};
    }
  `

  return <Global styles={(theme: any) => css([cssReset(theme), configCSS])} />
}

//
// only applies CSSReset on css-reset container
//
export default function GlobalCssProvider(props: React.PropsWithChildren<any>) {
  return (
    <div className="css-reset">
      <ThemeProvider
        theme={theme}
      >
        <CSSReset />
        {props.children}
      </ThemeProvider>
    </div>
  )
}

export { GlobalCssProvider }
