import React, { forwardRef } from 'react'
import BaseSelect, { Props } from 'react-select'

export interface ExtendedSelectProps extends Props {
  testId?: string
  label?: string
  border?: React.CSSProperties
  isRequired?: boolean
  minWidth?: number | string
}

const BORDER__ACTION_COLOR = 'gray.60'

export type SelectProps = ExtendedSelectProps & React.RefAttributes<any>

export const Select: React.FC<SelectProps> = forwardRef(
  ({ border, testId, label, minWidth, ...props }, ref) => {
    return (
      <BaseSelect
        ref={ref}
        classNamePrefix="control-select"
        data-testid={testId}
        aria-label={label}
        theme={(theme) => ({
          ...theme,
          spacing: {
            ...theme.spacing,
            controlHeight: 42,
          },
        })}
        styles={{
          container: (provided) => ({
            ...provided,
            minWidth: minWidth ?? 100,
          }),
          control: (provided, state) => ({
            ...provided,
            '&:hover': { borderColor: BORDER__ACTION_COLOR }, // border style on hover
            '&:focus': { borderColor: BORDER__ACTION_COLOR }, // border style on hover
            boxShadow: state.isFocused ? 'none' : 'none',
            ...border,
          }),
        }}
        {...props}
      />
    )
  },
)
