import { Icon, IconProps } from '@chakra-ui/react'

export const EvrKeyDisabled = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" {...props}>
    <path
      fill={'#0206A8'}
      d="m40.75 45-15.9-15.85h-.3Q23 32.8 20.15 34.4 17.3 36 14 36q-5 0-8.5-3.5T2 24q0-3.3 1.75-6.25t5.1-4.55l-5.8-5.85 2.15-2.1 37.7 37.6Zm1.6-26.15L48 24.5l-8.35 7.65-4.4-3.2-1.15.85-2.15-2.15 3.35-2.4 4.15 3.05 4.2-3.9-2.55-2.55h-15l-3-3ZM14 33q2.55 0 4.975-1.675t3.375-4.675l-2.975-2.975-2.625-2.625-2.625-2.625-2.975-2.975q-3.2 1.1-4.675 3.55Q5 21.45 5 24q0 3.75 2.625 6.375T14 33Zm0-5.6q-1.4 0-2.4-1t-1-2.4q0-1.4 1-2.4t2.4-1q1.45 0 2.425 1 .975 1 .975 2.4t-1 2.4q-1 1-2.4 1Z"
    />
  </Icon>
)
