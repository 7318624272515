import { Icon, IconProps } from '@chakra-ui/react'

export const EvrChevronRight = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 20c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l8.646-8.646-8.646-8.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l9 9c0.195 0.195 0.195 0.512 0 0.707l-9 9c-0.098 0.098-0.226 0.146-0.354 0.146z"
      fill="currentColor"
    ></path>
  </Icon>
)
