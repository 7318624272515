
export const DEFAULT_DOCUMENT_EXPIRATION = [
  {
    value: '1',
    label: '1 Day',
  },
  {
    value: '3',
    label: '3 days',
  },
  {
    value: '7',
    label: '7 days',
  },
  {
    value: '14',
    label: '2 weeks',
  },
  {
    value: '21',
    label: '3 weeks',
  },
  {
    value: '30',
    label: '1 month',
  },
  {
    value: '90',
    label: '3 months',
  },
  {
    value: '180',
    label: '6 months',
  },
]

export const EXPIRATION_REMINDER_TIMING_DAYS = [
  { value: '1', label: '1 day' },
  { value: '2', label: '2 days' },
  { value: '3', label: '3 days' },
  { value: '4', label: '4 days' },
  { value: '5', label: '5 days' },
  { value: '6', label: '6 days' },
  { value: '7', label: '1 week' },
  { value: '14', label: '2 weeks' },
  { value: '21', label: '3 weeks' },
  { value: '30', label: '1 month' },
]

export const REMINDER_TIMING_DAYS = [
  { value: '1', label: '1 day' },
  {
    value: '2',
    label: '2 days',
  },
  {
    value: '3',
    label: '3 days',
  },
  {
    value: '4',
    label: '4 days',
  },
  {
    value: '5',
    label: '5 days',
  },
  {
    value: '6',
    label: '6 days',
  },
  {
    value: '7',
    label: '1 week',
  },
  {
    value: '14',
    label: '2 weeks',
  },
  {
    value: '21',
    label: '3 weeks',
  },
  {
    value: '30',
    label: '1 month',
  },
  {
    value: '90',
    label: '3 months',
  },
]
