import { getValueOfOption } from '../BusinessSettings/ExpirationAndReminders/ExpirationAndReminders.utils'
import { EversignDateFormat } from '../BusinessSettings/GeneralPreferences/GeneralPreferences.constant'
import {
  getDateFormatSelection,
  getTimeFormatSelection,
  getTimeZoneSelection,
} from '../BusinessSettings/GeneralPreferences/GeneralPreferences.utils'
import {
  UseUpdateUserSettingsPayload,
  UserSettingsFormData,
  UserSettingsResponse,
} from './UserSettings.types'

export function getUserSettingsFormData(
  data?: UserSettingsResponse,
): UserSettingsFormData | undefined {
  if (!data) return

  const transformedUserSettingsData = {
    show_all_signatures_in_signer_popup: data.show_all_signatures_in_signer_popup,
    date_format: getDateFormatSelection(data.date_format as EversignDateFormat),
    time_format: getTimeFormatSelection(data.time_format),
    default_time_zone: getTimeZoneSelection(data.default_time_zone),
    notification_document_opened_by_signer: data.notification_document_opened_by_signer,
    notification_daily_outstanding_summary: data.notification_daily_outstanding_summary,
  }

  return transformedUserSettingsData
}

export function transformToUserSettingsUpdate(
  data: UserSettingsFormData,
): UseUpdateUserSettingsPayload {
  const {
    date_format,
    time_format,
    default_time_zone,
    show_all_signatures_in_signer_popup,
    notification_document_opened_by_signer,
    notification_daily_outstanding_summary,
  } = data

  return {
    postArray: {
      date_format: getValueOfOption(date_format),
      time_format: getValueOfOption(time_format),
      default_time_zone: getValueOfOption(default_time_zone),
      show_all_signatures_in_signer_popup: show_all_signatures_in_signer_popup,
      notification_document_opened_by_signer: notification_daily_outstanding_summary,
      notification_daily_outstanding_summary: notification_document_opened_by_signer,
    },
  }
}
