// import { useMutation } from '../../hooks/use-api'
import { useMutation } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { ContactDetailsResponseType, EditContactResponseType, UseSaveEditContactPayload } from '../../Features/Contacts/edit/ContactEdit.types'

import { SelectFieldType } from '../../Features/Contacts/edit/ContactEdit.types'
import { getValueOfSelectFieldType } from '../../Features/Contacts/edit/ContactEdit.utils'



export interface UseNewContactPayload {
  first_name?: string
  last_name?: string
  email_address?: string
  company_name?: string
  website_url?: string
  default_language?: SelectFieldType
  notes?: string
  address_1?: string
  address_2?: string
  zip_code?: string
  state?: string
  city?: string
  country_code?: SelectFieldType
  phone_number?: string
}

export interface UseNewContactResponse {
  message: string
  success: 0 | 1
}

const newContactMutation = async (
  baseOrigin: string,
  payload: UseNewContactPayload,
): Promise<UseNewContactResponse> => {
  const url = `${baseOrigin}/bff/contacts/new`

  const extractedDefaultLanguageString = payload.default_language
    ? payload.default_language.value
    : null

  const extractCountryString = payload.country_code?.value ? payload.country_code.value : null

  const normalizedPayload = {
    ...payload,
    default_language: extractedDefaultLanguageString,
    country_code: extractCountryString,
  }

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(normalizedPayload),
  })

  if (res.status === 400) {
    // try to get json error from 400 response, so UI errors are as specific as possible
    try {
      const result = await res.json()
      throw new Error(result.error.message)
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : String(error))
    }
  }
  
  try {
    const data = await res.json()
    return data as UseNewContactResponse
  } catch (error) {
    console.error(error)
    throw new Error('Response was not json')
  }
}

export function useNewContact(baseOrigin: string) {
  const mutation = useMutation((payload: UseNewContactPayload) =>
    newContactMutation(baseOrigin, payload),
  )
  return mutation
}

export const fetchEditContact = async (contactHash: string): Promise<EditContactResponseType> => {
  const url = `/bff/contacts/edit?id=${contactHash}`

  const res = await fetch(url, {
    method: 'GET',
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const result = await res.json()
    return result
  } catch (error) {
    throw new Error('Response was not json')
  }
}

export function useFetchEditContact(id: string) {
  return useQuery(['edit-contact', id], () => fetchEditContact(id), {
    retry: 0,
  })
}

export const saveContact = async (contactHash: string, payload: UseSaveEditContactPayload): Promise<any> => {
  const url = `/bff/contacts/save?id=${contactHash}`

  const extractedDefaultLanguageString = getValueOfSelectFieldType(
    payload?.postArray?.default_language,
  )
  const extractCountryString = getValueOfSelectFieldType(payload?.postArray?.country_code)

  const normalizedPayload = {
    ...payload,
    postArray: {
      ...payload.postArray,
      default_language: extractedDefaultLanguageString,
      country_code: extractCountryString,
    },
  }

  const formData = new FormData()
  formData.append('postArray', JSON.stringify(normalizedPayload.postArray))
  formData.append('id', JSON.stringify(normalizedPayload.id))

  const csrfToken =
    document?.querySelector("meta[name='Csrf-Token']")?.getAttribute('content') ?? 'no token given'

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Csrf-Token': csrfToken,
    },
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const response = await res.json()
    return response
  } catch (error) {
    console.error(error)
    throw new Error('Response was not json')
  }
}

export function useSaveContact(contactHash: string) {
  const mutation = useMutation((payload: UseSaveEditContactPayload) =>
    saveContact(contactHash, payload),
  )
  return mutation
}

export const fetchContactDetails = async (contactHash: string): Promise<ContactDetailsResponseType> => {
  const url = `/bff/contacts/view?id=${contactHash}`

  const res = await fetch(url, {
    method: 'GET',
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const result = await res.json()
    return result
  } catch (error) {
    throw new Error('Response was not json')
  }
}


export function useFetchContactDetails(id: string) {
  return useQuery(['contact-details', id], () => fetchContactDetails(id), {
    retry: 0,
  })
}
