import { Box, Flex, FlexProps } from '..'

export function StepGuide({ children, ...props }: React.PropsWithChildren<FlexProps>) {
  return (
    <Flex {...props}>
      <Box flex={1}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          {children}
        </Flex>
      </Box>
    </Flex>
  )
}
