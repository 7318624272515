import { Text as Base, BoxProps as Props } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { Namespace, Trans } from 'react-i18next'

export interface TextProps extends Props {
  testId?: string
}

export const BaseText = forwardRef(
  ({ testId, ...props }: TextProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <Base
        data-testid={testId}
        fontSize="md"
        {...props}
        ref={ref}
      />
    )
  },
)

export interface TextProps extends Props {
  i18nKey?: string
  ns?: Namespace
  testId?: string
}

export function Text({ children, ns, testId, i18nKey, ...props }: TextProps) {
  const translation = (
    <Trans ns={ns} i18nKey={i18nKey}>
      {children}
    </Trans>
  )
  return (
    <BaseText data-testid={testId} {...props}>
      {i18nKey ? translation : children}
    </BaseText>
  )
}

export default Text
