import { CARD_SECTION_BORDER_COLOR, Stack, StackProps } from '..'

export function CardFooter({ children, ...props }: StackProps) {
  return (
    <Stack
      borderTopWidth={1}
      borderTopStyle="solid"
      borderTopColor={CARD_SECTION_BORDER_COLOR}
      m={5}
      mb="15px"
      pt={'15px'}
      flex={1}
      isInline
      spacing={4}
      shouldWrapChildren={true}
      {...props}
    >
      {children}
    </Stack>
  )
}
