import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import BaseSelect, { Props } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Box } from '..'

const BORDER__ACTION_COLOR = 'gray.100'
const BG_COLOR = 'white'
const BORDER_COLOR = 'gray.50'

export const FONT_PNR = `"pn-r", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif`
export const FONT_PNSB = `"pn-sb", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif`

interface BaseProps {
  maxWidth?: number | string
  minWidth?: number | string
  width?: number | string
  mt?: number
  mr?: number
}

export type AutocompletSelectProps = Props<any>

export const BASE_STYLE = {
  //@ts-ignore
  control: (provided) => ({
    ...provided,
    background: BG_COLOR,
    borderColor: BORDER_COLOR,
    fontFamily: FONT_PNR,
    fontSize: '14px',
    color: '#4b4b57',
    borderWidth: 2,
    borderRadius: '2px',
    height: '35px',
    minHeight: '35px',
    '&:hover': { borderColor: BORDER__ACTION_COLOR }, // border style on hover
    '&:focus': { borderColor: BORDER__ACTION_COLOR, outline: 'none' }, // border style on hover
    boxShadow: 'none',
  }),
  //@ts-ignore
  menu: (provided) => ({
    ...provided,
    fontFamily: FONT_PNR,
    fontSize: '14px',
    color: '#4b4b57',
    backgroundColor: BG_COLOR,
  }),
  //@ts-ignore
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  //@ts-ignore
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? BORDER_COLOR : BG_COLOR,
    color: '#4b4b57',
    fontFamily: state.isSelected ? FONT_PNSB : FONT_PNR,
  }),
  //@ts-ignore
  valueContainer: (provided) => ({
    ...provided,
    height: '35px',
    padding: '0 4px',
    '&:hover': { borderColor: BG_COLOR }, // border style on hover
    '&:focus': { borderColor: BG_COLOR, outline: 'none' }, // border style on hover
  }),
  //@ts-ignore
  input: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    margin: 0,
    marginBottom: 2,
    padding: 0,
  }),

  //@ts-ignore
  indicatorSeparator: () => ({
    display: 'none',
  }),
  //@ts-ignore
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '31px',
  }),
}

export const BASE_THEME = (theme: any) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 12,
    baseUnit: 2,
  },
})

//
// TODO AutocompletSelect is not used anymore
// dead code remove me 
//
export function AutocompletSelect({
  name,
  options,
  mt,
  mr,
  width,
  maxWidth,
  minWidth,
  defaultValue,
  ...props
}: AutocompletSelectProps & BaseProps) {
  const { control } = useFormContext()

  return (
    <Box width={width} maxWidth={maxWidth} minWidth={minWidth} mt={mt} mr={mr}>
      <Controller
        data-testid="control-select-container"
        name={name ?? 'select'}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => (
          <BaseSelect
            id={name}
            classNamePrefix="control-select"
            data-testid={'control-select'}
            options={options}
            aria-label={name}
            defaultValue={defaultValue ?? ''}
            getOptionValue={(a) => a.value}
            theme={BASE_THEME}
            styles={BASE_STYLE}
            {...field}
            {...props}
          />
        )}
      />
    </Box>
  )
}
export function CreatableAutocompletSelect({
  name,
  options: propOptions,
  mt,
  width,
  defaultValue,
  ...props
}: AutocompletSelectProps & BaseProps) {
  const { control, setValue } = useFormContext()

  const [options, setOptions] = React.useState(propOptions)

  const handleCreate = (inputValue: any) => {
    setValue(name ?? 'select', {
      label: inputValue,
      value: inputValue,
    })
    setOptions([
      //@ts-ignore
      {
        label: inputValue,
        value: inputValue,
      },
      //@ts-ignore
      ...options,
    ])
    return {
      label: inputValue,
      value: inputValue,
    }
  }
  return (
    <Box width={width} mt={mt}>
      <Controller
        data-testid="control-select-container"
        name={name ?? 'select'}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field, fieldState }) => (
          <CreatableSelect
            onCreateOption={handleCreate}
            id={name}
            classNamePrefix="control-select"
            data-testid={'control-select'}
            options={options}
            aria-label={name}
            defaultValue={defaultValue ?? ''}
            getOptionValue={(a) => a.value}
            formatCreateLabel={(value: string) => `Create unit ${value}`}
            theme={BASE_THEME}
            styles={BASE_STYLE}
            {...field}
            {...props}
          />
        )}
      />
    </Box>
  )
}
