import {
  BusinessSettingsResponse,
  ExpirationAndRemindersRequest,
} from '../../../api/legacy/use-business-settings.types'
import {
  DEFAULT_DOCUMENT_EXPIRATION,
  EXPIRATION_REMINDER_TIMING_DAYS,
  REMINDER_TIMING_DAYS,
} from './ExpirationAndReminders.constant'
import { ExpirationAndRemindersFromData, SelectOption } from './ExpirationAndReminders.types'

const getSelectedDefaultDocumentExpiration = (days?: number) =>
  DEFAULT_DOCUMENT_EXPIRATION.find((p) => p.value === days?.toString()) ??
  DEFAULT_DOCUMENT_EXPIRATION[6]

const getExpirationReminderTimingDays = (days?: number) =>
  EXPIRATION_REMINDER_TIMING_DAYS.find((p) => p.value === days?.toString()) ??
  EXPIRATION_REMINDER_TIMING_DAYS[2]

const getReminderTimingDays = (days?: number) =>
  REMINDER_TIMING_DAYS.find((p) => p.value === days?.toString()) ?? REMINDER_TIMING_DAYS[2]

export const convertToBoolean = (b: string | number) => {
  if (b === '1' || b === 1) return true
  return false
}
export const booleanToNumber = (b: boolean): 0 | 1 => {
  if (b) return 1
  return 0
}
export function getValueOfOption(s: SelectOption) {
  return s.value
}

export const getExpirationAndRemindersFormData = (
  data?: BusinessSettingsResponse,
): ExpirationAndRemindersFromData | undefined => {
  if (!data) return
  return {
    default_document_expiration: getSelectedDefaultDocumentExpiration(
      data.default_document_expiration,
    ),
    expiration_reminder_timing_days: getExpirationReminderTimingDays(
      data.expiration_reminder_timing_days,
    ),
    first_reminder_timing_days: getReminderTimingDays(data.first_reminder_timing_days),
    second_reminder_timing_days: getReminderTimingDays(data.second_reminder_timing_days),

    allow_user_override_expiration_settings: convertToBoolean(
      data.allow_user_override_expiration_settings,
    ),
    enable_reminders: convertToBoolean(data.enable_reminders),
    enable_expiration_reminders: convertToBoolean(data.enable_expiration_reminders),
  }
}


export function transformToExpirationAndRemindersRequest({
  default_document_expiration,
  enable_expiration_reminders,
  enable_reminders,
  first_reminder_timing_days,
  second_reminder_timing_days,
  allow_user_override_expiration_settings,
  expiration_reminder_timing_days,
}: Required<ExpirationAndRemindersFromData>): ExpirationAndRemindersRequest {
  return {
    default_document_expiration: getValueOfOption(default_document_expiration),
    expiration_reminder_timing_days: getValueOfOption(expiration_reminder_timing_days),
    first_reminder_timing_days: getValueOfOption(first_reminder_timing_days),
    second_reminder_timing_days: getValueOfOption(second_reminder_timing_days),

    allow_user_override_expiration_settings: allow_user_override_expiration_settings ? 1 : 0,
    enable_reminders: enable_reminders ? 1 : 0,
    enable_expiration_reminders: enable_expiration_reminders ? 1 : 0,
  }
}
