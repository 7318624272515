import { Box, BoxProps, CARD_BORDER_COLOR } from '..'

const CARD_HEADER_BG_COLOR = 'gray.50'
export const CARD_HEADER_FONT_COLOR = 'navy.100'
const CARD_SECONDARY_HEADER_BG_COLOR = 'gray.50'
export const CARD_SECONDARY_HEADER_FONT_COLOR = 'navy.100'

export function CardHeader({ children, ...props }: BoxProps) {
  return (
    <Box
      px={'20px'}
      py={'10px'}
      bg={CARD_HEADER_BG_COLOR}
      color={CARD_HEADER_FONT_COLOR}
      fontFamily={'pn-sb'}
      fontSize={'14px'}
      fontWeight={400}
      {...props}
    >
      {children}
    </Box>
  )
}
export function CardHeaderSecondary(props: BoxProps) {
  return (
    <CardHeader
      borderBottomColor={CARD_BORDER_COLOR}
      borderBottomWidth={1}
      bg={CARD_SECONDARY_HEADER_BG_COLOR}
      color={CARD_HEADER_FONT_COLOR}
      {...props}
    />
  )
}
