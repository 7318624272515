import { Box } from '..'
import { COLOR_STEP_GUIDE_BUTTON_BORDER_ACTIVE } from './StepGuideButton'

const LEFT_CONNECTOR_WIDTH = 82
const LEFT_CONNECTOR_ACTIVE = 1
const LEFT_CONNECTOR_INACTIVE = 0.4

interface LeftConnectorProps {
  active?: boolean
}

export function LeftConnector({ active = false }: LeftConnectorProps) {
  return (
    <Box
      flex={['auto', 'auto', 1, 1]}
      h={2}
      w={['3px', '3px', LEFT_CONNECTOR_WIDTH * 0.75, LEFT_CONNECTOR_WIDTH * 1]}
      height={'2px'}
      bg={COLOR_STEP_GUIDE_BUTTON_BORDER_ACTIVE}
      opacity={active ? LEFT_CONNECTOR_ACTIVE : LEFT_CONNECTOR_INACTIVE}
    />
  )
}
