import { Icon, IconProps } from '@chakra-ui/react'

export const EvrExpand = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.854 7.146l-6.146-6.146h3.793c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-5c-0.276 0-0.5 0.224-0.5 0.5v5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-3.793l6.146 6.146c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707z"
      fill="currentColor"
    ></path>
    <path
      d="M19.5 0h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h3.793l-6.146 6.146c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.353 0.146s0.256-0.049 0.353-0.146l6.147-6.146v3.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M7.854 12.147c-0.195-0.195-0.512-0.195-0.707 0l-6.146 6.146v-3.793c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v5c0 0.276 0.224 0.5 0.5 0.5h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3.793l6.146-6.147c0.195-0.195 0.195-0.512 0-0.707z"
      fill="currentColor"
    ></path>
    <path
      d="M19.5 14c-0.276 0-0.5 0.224-0.5 0.5v3.793l-6.147-6.146c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l6.147 6.146h-3.793c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h5c0.276 0 0.5-0.224 0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5z"
      fill="currentColor"
    ></path>
  </Icon>
)
