/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'

export interface FormProps {}

export function Form({ children, ...props }: React.PropsWithChildren<FormProps>) {
  return (
    // necessary for test
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <form role="form" noValidate {...props}>
      {children}
    </form>
  )
}
