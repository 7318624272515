import { Textarea as Base, TextareaProps as Props } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface TextareaProps extends Props {
  testId?: string
}

export const Textarea = forwardRef(
  ({ testId, ...props }: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    return (
      <Base
        data-testid={testId}
        color={'navy.100'}
        paddingY={'5px'}
        paddingX={'3px'}
        borderWidth={'1px'}
        borderColor="gray.60"
        fontWeight={'normal'}
        {...props}
        ref={ref}
      />
    )
  },
)

export default Textarea
