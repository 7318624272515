import { Tooltip as Base, TooltipProps as Props } from '@chakra-ui/react'
import React from 'react'

export interface TooltipProps extends Omit<Props, 'aria-label'> {
  /**
   * testId is used in SAT/e2e as element selector
   *
   * @requires
   * @type {string}
   * @memberof ButtonProps
   */
  testId?: string

  label: string
  //
  // Tooltip has an bug when an button is disabled the
  // blur event wound not fire
  //
  isDisabled?: boolean
}

export function Tooltip({
  testId = 'Tooltip',
  children,
  label,
  isDisabled,
  ...props
}: TooltipProps) {
  if (isDisabled) {
    return <React.Fragment>{children}</React.Fragment>
  }
  return (
    <Base shouldWrapChildren={true} label={label} aria-label={label} hasArrow zIndex={1} {...props}>
      {children}
    </Base>
  )
}
