import { COUNTRY_LIST } from "../../../constants";
import { SelectFieldType } from "./ContactEdit.types";

export function getValueOfSelectFieldType(s: SelectFieldType | undefined) {
  return s?.value
}

export const getCountrySelection = (value?: string) => {
  return COUNTRY_LIST.find((p) => p.value === value?.toString())
}
