import { Flex } from '..'

interface StepGuideButtonProps {
  active?: boolean
}

const COLOR_STEP_GUIDE_BUTTON_BORDER = 'gray.60'
export const COLOR_STEP_GUIDE_BUTTON_BORDER_ACTIVE = 'indigo.100'

export function StepGuideButton({
  active = false,
  children,
  ...props
}: React.PropsWithChildren<StepGuideButtonProps>) {
  return (
    <Flex
      minWidth={['100%', '100%', 75, 120]}
      px={4}
      pt={2}
      pb={2}
      borderWidth={2}
      borderStyle="solid"
      borderColor={active ? COLOR_STEP_GUIDE_BUTTON_BORDER_ACTIVE : COLOR_STEP_GUIDE_BUTTON_BORDER}
      borderRadius={3}
      color={COLOR_STEP_GUIDE_BUTTON_BORDER_ACTIVE}
      fontWeight={'medium'}
      alignItems="center"
      justifyContent="center"
      lineHeight="1"
      {...props}
    >
      {children}
    </Flex>
  )
}
