import { StackProps, Stack, Text } from '..'
import { ResponseError } from '../../hooks/fetcher'

type ErrorTypes<T> = ResponseError<T> | Error | string
interface SomethingBadHappenedProps<T = unknown> extends StackProps {
  error?: ErrorTypes<T>
}

const NOT_JSON_RESPONSE_ERROR = 'Unexpected token < in JSON'
const UNKNOWN_ERROR_OCCURRED = 'Unknown error occurred'

export function SomethingBadHappened(props: SomethingBadHappenedProps) {
  function getErrorMessage(error?: ErrorTypes<unknown>) {
    if (!error) return null
    if (typeof error === 'string') {
      return error
    }
    if (error.message) {
      if (error.message.includes(NOT_JSON_RESPONSE_ERROR)) {
        return UNKNOWN_ERROR_OCCURRED
      }
      return error.message
    }
    return UNKNOWN_ERROR_OCCURRED
  }

  return (
    <Stack
      alignItems="center"
      bg="white"
      borderColor={'gray.50'}
      borderWidth={'1px'}
      borderRadius={'md'}
      justifyContent={'center'}
      maxHeight="210px"
      minHeight="210px"
      p={4}
      shouldWrapChildren={true}
      spacing={4}
      {...props}
    >
      <Text fontSize="18px" fontFamily="pn-sb" fontWeight={400}>
        Something bad happened
      </Text>
      <Text>{getErrorMessage(props.error)}</Text>
    </Stack>
  )
}
