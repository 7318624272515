import { BusinessSettingsResponse } from '../../../api/legacy/use-business-settings.types'
import {
  booleanToNumber,
  convertToBoolean,
  getValueOfOption,
} from '../ExpirationAndReminders/ExpirationAndReminders.utils'
import {
  createDateFormatSelection,
  DEFAULT_FONT,
  DEFAULT_LANGUAGE,
  DEFAULT_SIGNER_GROUP,
  DOCUMENT_LIST_DEFAULT_TAB,
  DOCUMENT_LIST_TAB,
  EversignDateFormat,
  SIGNER_GROUP,
  TIMEZONE_LIST,
  TIME_FORMAT,
} from './GeneralPreferences.constant'
import { GeneralPreferencesFormData, GeneralPreferencesRequest } from './GeneralPreferences.types'

export const getDateFormatSelection = (format?: string) => {
  const selection = createDateFormatSelection()
  return selection.find((p) => p.value === format?.toString()) ?? selection[2]
}

export const getTimeFormatSelection = (format?: number) => {
  return TIME_FORMAT.find((p) => p.value === format?.toString()) ?? TIME_FORMAT[0]
}
const getFontSelection = (value?: string) => {
  return DEFAULT_FONT.find((p) => p.value === value?.toString()) ?? TIME_FORMAT[0]
}

export const getTimeZoneSelection = (value?: string) => {
  return (
    TIMEZONE_LIST.find((p) => p.value === value?.toString()) ??
    TIMEZONE_LIST[TIMEZONE_LIST.length - 1]
  )
}

export const getLanguageSelection = (value?: string) => {
  return DEFAULT_LANGUAGE.find((p) => p.value === value?.toString()) ?? DEFAULT_LANGUAGE[0]
}
const getDocumentListTabSelection = (value?: string) => {
  return DOCUMENT_LIST_TAB.find((p) => p.value === value?.toString()) ?? DOCUMENT_LIST_DEFAULT_TAB
}
const getSignerGroupSelection = (value?: string) => {
  return SIGNER_GROUP.find((p) => p.value === value?.toString()) ?? DEFAULT_SIGNER_GROUP
}

const getRestrictGlobalTemplates = (data: BusinessSettingsResponse) => {
  // default_template_permission:
  // 	$(this).find('[name="default_template_permission"]').prop('checked') === true ?
  // 		($(this).find('[name="restrict_global_templates"]').prop('checked') === true ? 3 : 2) : 1,
  // restrict_global_templates: convertToBoolean(data.restrict_global_templates),

  if (data.default_template_permission === 3) {
    return {
      default_template_permission: true,
      restrict_global_templates: true,
    }
  } else if (data.default_template_permission === 2) {
    return {
      default_template_permission: true,
      restrict_global_templates: false,
    }
  }
  return {
    default_template_permission: false,
    restrict_global_templates: undefined,
  }
}

export function getGeneralPreferencesFormData(
  data?: BusinessSettingsResponse,
): GeneralPreferencesFormData | undefined {
  if (!data) return

  return {
    all_signatures_required: convertToBoolean(data.document_require_all_signers_default_option),
    completed_add_signer_attachments: convertToBoolean(
      data.add_signer_attachments_to_completed_document,
    ),
    default_font: getFontSelection(data.default_font),
    activate_signing_order_by_default: convertToBoolean(data.activate_signing_order_by_default),

    date_format: getDateFormatSelection(data.date_format as EversignDateFormat),
    time_format: getTimeFormatSelection(data.time_format),
    default_time_zone: getTimeZoneSelection(data.default_time_zone),
    default_language: getLanguageSelection(data.default_language),
    document_list_default_tab: getDocumentListTabSelection(data.document_list_default_tab),
    default_signer_group: getSignerGroupSelection(data.default_signer_group),
    enable_contact_autocomplete: convertToBoolean(data.enable_contact_autocomplete),
    enable_contact_autosave: convertToBoolean(data.enable_contact_autosave),
    enable_ui_hidden_tag_option: convertToBoolean(data.enable_ui_hidden_tag_option),
    enable_third_party_upload: convertToBoolean(data.enable_third_party_upload),
    ...getRestrictGlobalTemplates(data),
  }
}

const setValueForDefaultTemplatePermission = (
  data: Pick<
    GeneralPreferencesFormData,
    'default_template_permission' | 'restrict_global_templates'
  >,
): 1 | 2 | 3 => {
  if (data.restrict_global_templates) {
    return 3
  } else if (data.default_template_permission) {
    return 2
  }
  return 1
}

export function transformToGeneralPreferencesRequest(
  data: GeneralPreferencesFormData,
): GeneralPreferencesRequest {
  const {
    all_signatures_required,
    completed_add_signer_attachments,
    activate_signing_order_by_default,
    default_template_permission,
    restrict_global_templates,
    default_font,
    date_format,
    time_format,
    default_time_zone,
    default_language,
    document_list_default_tab,
    default_signer_group,
    enable_contact_autocomplete,
    enable_contact_autosave,
    enable_ui_hidden_tag_option,
    enable_third_party_upload,
  } = data

  return {
    document_require_all_signers_default_option: booleanToNumber(all_signatures_required),
    add_signer_attachments_to_completed_document: booleanToNumber(completed_add_signer_attachments),
    activate_signing_order_by_default: booleanToNumber(activate_signing_order_by_default),
    default_template_permission: setValueForDefaultTemplatePermission({
      default_template_permission,
      restrict_global_templates,
    }),
    default_font: getValueOfOption(default_font),
    date_format: getValueOfOption(date_format),
    time_format: getValueOfOption(time_format),
    default_time_zone: getValueOfOption(default_time_zone),
    default_language: getValueOfOption(default_language),
    document_list_default_tab: getValueOfOption(document_list_default_tab),
    default_signer_group: getValueOfOption(default_signer_group),
    enable_contact_autocomplete: booleanToNumber(enable_contact_autocomplete),
    enable_contact_autosave: booleanToNumber(enable_contact_autosave),
    enable_ui_hidden_tag_option: booleanToNumber(enable_ui_hidden_tag_option),
    enable_third_party_upload: booleanToNumber(enable_third_party_upload),
  }
}
