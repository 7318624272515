import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuItemProps as Props,
  MenuList,
  MenuProps,
  theme,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Box, BoxProps, EvrCaretDown, EvrCaretUp, ListButton, ListButtonProps } from '..'
import Flex from '../Flex'

import { DropdownMenuProps as Base } from './Dropdown.types'

const MENU_BORDER_COLOR = 'gray.60'
export const SECONDARY_BG_COLOR = 'gray.50'
const BORDER_COLOR = 'gray.60'
const ICON_COLOR = 'gray.100'

interface MenuItemProps extends Props {
  testId: string
}

export const BORDER_COLOR_PRIMARY = 'gray.60'
export const BORDER_COLOR_SECONDARY = 'gray.50'
export const BG_COLOR_SECONDARY = 'gray.50'
export const BG_COLOR_HOVER_SECONDARY = 'gray.50'

export const DropdownItem = React.forwardRef(
  ({ testId, children, ...props }: MenuItemProps, ref: React.Ref<HTMLButtonElement>) => (
    <MenuItem

      data-testid={testId}
      bg="gray.50"
      fontSize={'14px'}
      py={2}
      fontFamily="pn-r"
      _hover={{
        bg: BG_COLOR_SECONDARY,
        borderColor: BORDER_COLOR_PRIMARY,
      }}
      _active={{
        bg: BG_COLOR_SECONDARY,
        borderColor: BORDER_COLOR_PRIMARY,
      }}
      _focus={{
        bg: SECONDARY_BG_COLOR,
      }}
      // @Global css
      // MenuItem would be an button but in the css reset it has the default of 100% font-size (16px) which has a higher
      // priority then the chakra css
      as="span"
      cursor="pointer"
      ref={ref}
      {...props}
    >
      {children}
    </MenuItem>
  ),
)

export const DropdownButton = ({
  children,
  isDisabled,
  bg = 'gray.60',
  isOpen,
  ...props
}: ListButtonProps & MenuButtonProps & { isOpen?: boolean }) => {
  return (
    <ListButton
      borderRadius={2}
      isDisabled={isDisabled}
      as={MenuButton}
      rightIcon={
        <Flex position={'absolute'} alignItems="center" bottom={'-2px'} h={'100%'}>
          {!isOpen ? <EvrCaretDown /> : <EvrCaretUp />}
        </Flex>
      }
      color={ICON_COLOR}
      alignItems="center"
      {...props}
    >
      {children}
    </ListButton>
  )
}

export const DropdownDivider = ({ testId, ...props }: BoxProps) => (
  <MenuDivider data-testid={testId} my={0} borderWidth={1} borderColor={BORDER_COLOR} {...props} />
)

type DropdownMenuProps = MenuProps & Base & BoxProps & { testIdSuffix?: string, width?: string }

export function Dropdown({
  //Menu props
  isOpen,
  autoSelect = false,
  closeOnBlur = true,
  closeOnSelect = true,
  width,
  //
  children,
  renderMenu,
  renderPrefix,
  placement = 'auto-start',
  menuListProps,
  isDisabled,
  minWidth = '156px',
  bg,
  color = 'navy.100',
  fontWeight,
  paddingRight,
  buttonProps,
  testIdSuffix = 'button',
  testId,
  ...props
}: DropdownMenuProps) {
  return (
    <Menu
      isOpen={isOpen}
      autoSelect={autoSelect}
      closeOnSelect={closeOnSelect}
      closeOnBlur={closeOnBlur}
    >
      {({ isOpen }) => (
        <Box data-testid={testId ?? 'dropdown'} borderRadius={2} {...props}>
          {renderPrefix && renderPrefix}
          <DropdownButton
            testId={`dropdown-${testIdSuffix}`}
            isDisabled={isDisabled}
            borderTopRightRadius={2}
            borderBottomRightRadius={2}
            // when an prefix is present the padding adds to mutch space
            pl={renderPrefix ? 0 : [1, 2]}
            isOpen={isOpen}
            _focus={{
              outline: 'none',
            }}
            _active={{
              bg: buttonProps?.bg ?? BG_COLOR_SECONDARY,
              borderColor: buttonProps?.borderColor ?? BORDER_COLOR_PRIMARY,
            }}
            _hover={{
              bg: buttonProps?.bg ?? BG_COLOR_SECONDARY,
              borderColor: buttonProps?.borderColor ?? BORDER_COLOR_PRIMARY,
            }}
            bg={bg}
            paddingRight={paddingRight}
            color={color}
            fontWeight={fontWeight}
            width={width}
            lineHeight={1}
            {...buttonProps}
          >
            {renderMenu && renderMenu}
          </DropdownButton>
          <MenuList
            py={0}
            placement={placement}
            bg="gray.50"
            minWidth={minWidth}
            borderColor={MENU_BORDER_COLOR}
            borderWidth={1}
            zIndex={theme.zIndices.dropdown}
            borderStyle={'solid'}
            {...menuListProps}
          >
            {children}
          </MenuList>
        </Box>
      )}
    </Menu>
  )
}

export const HeaderDropdownMenuButton = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <MenuButton
      as={Button}
      rightIcon={isOpen ? <EvrCaretUp /> : <EvrCaretDown />}
      bg="indigo.100"
      color="white"
      fontSize={'14px !important'}
      fontWeight="medium"
      borderRadius={'9999px'}
      height="unset !important"
      onClick={() => {
        if (!isOpen) {
          setOpen(true)
        } else {
          setOpen(false)
        }
      }}
      px="26px"
      _focus={{
        bg: 'indigo.100',
      }}
      _hover={{
        bg: 'indigo.100',
      }}
    >
      Actions
    </MenuButton>
  )
}

export function BaseDropdown({ children, ...props }: MenuProps) {
  return <Menu {...props}>{children}</Menu>
}
