import { Box } from '@chakra-ui/react'
import { BoxProps, Stack, StackProps } from '..'

export const CARD_SECTION_BORDER_COLOR = 'gray.50'

export function CardSectionBase(props: BoxProps) {
  return (
    <Box
      borderBottomStyle="solid"
      borderBottomWidth={1}
      borderBottomColor={`${CARD_SECTION_BORDER_COLOR} !important`}
      _last={{
        borderBottomColor: 'transparent !important',
      }}
      {...props}
    />
  )
}

export function CardSection({ _hover, ...props }: StackProps & BoxProps) {
  return (
    <CardSectionBase _hover={_hover}>
      <Stack px={'20px'} py={'15px'} shouldWrapChildren={true} {...props} />
    </CardSectionBase>
  )
}
