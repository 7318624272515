import { useQuery } from '@tanstack/react-query'

export type RecentActivityItemType = {
  time_formatted: string
  date_formatted: string
  date_formatted_raw: string
  document_hash: string
  document_title: string
  statement_link: string
  activity_type: string
  activity_triggerd_by_user: string
  text: string
}

export type MostUsedTemplate = {
  document_creation_ts: number
  document_hash: string
  document_title: string
  is_template: number
  is_template_link: number
}

interface DashboardResponse {
  recentActivityArray: RecentActivityItemType[]
  awaitingMySignatureDocumentCount: number
  defaultSignature: string
  defaultInitials: string
  loggedInAsUsername: string
  documentsSentThisMonth: number
  contactsCount: number
  apiRequestsSentThisMonth: number
  mostUsedtemplatesArray: MostUsedTemplate[]
  recentDraftsArray: any
  subscriptionVolume: number
  subscriptionPlanName: string
  businessName: string
  showUpgradeButton: boolean
  templatesSentOverall: number
}

const fetchDashboardData = async () => {
  const res = await fetch('/bff/dashboard')
  return await res.json()
}

export function useDashboard(withError = false) {
  const { isLoading, data, error } = useQuery<DashboardResponse | undefined>(
    ['get_dashboard_data'],
    fetchDashboardData,
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    data,
    error,
    isLoading,
  }
}
