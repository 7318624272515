import { BoxProps, ColorProps, FormLabel as Base } from '@chakra-ui/react'
import React from 'react'

interface ILabelProps {
  color?: string | ColorProps
  isInvalid?: boolean
  /**
   * This prop is read from the `FormControl` context but can be passed as well.
   * If passed, it'll override the context and give the `label` look disabled
   */
  isDisabled?: boolean
  children: React.ReactNode
}

export type Props = ILabelProps & BoxProps & React.LabelHTMLAttributes<unknown>

// Problems with importing FormLabelProps

const LABEL_COLOR = 'navy.100'

export function FormLabel({ color = LABEL_COLOR, ...props }: Props) {
  return (
    <Base
      color={color}
      fontSize={'text.base !important'}
      {...props}
      pb={'5px'}
    />
  )
}
