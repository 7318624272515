import { extendTheme } from '@chakra-ui/react'
import { ButtonProps } from './primitives'

export const FONT_PN_R = `"pn-r", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif`
export const FONT_PN_SB = `"pn-sb", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif`

export const theme = extendTheme({
  fonts: {
    heading: `facto, 'Calibri Light', Calibri, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    body: `facto,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    national: `National-Light, 'Calibri Light', Calibri, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    button: `facto, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    // in app
    'pn-sb': FONT_PN_SB,
    'pn-r': FONT_PN_R,
  },
  colors: {
    navy: {
      10: '#e6e6ec',
      20: '#ccced8',
      30: '#b3b5c5',
      40: '#999cb1',
      50: '#80849e',
      60: '#666b8b',
      70: '#4d5277',
      80: '#333964',
      90: '#1a2150',
      100: '#00083D',
  },
    teal: {
      10: '#e6fdf5',
      20: '#ccfbeb',
      30: '#b3f9e1',
      40: '#99f7d7',
      50: '#80f5cd',
      60: '#66f3c3',
      70: '#4df1b9',
      80: '#33efaf',
      90: '#1aeda5',
      100: '#00EB9B',
    },
    indigo: {
      10: '#e6e6f6',
      20: '#cccdee',
      30: '#b3b4e5',
      40: '#9a9bdc',
      50: '#8183d4',
      60: '#676acb',
      70: '#4e51c2',
      80: '#3538b9',
      90: '#1b1fb1',
      100: '#0206A8',
  },
    yellow: {
      100: '#E7E710',
    },
    lightGray: {
      100: '#E8EFF6',
    },
    lightBlue: {
      100: '#00E2EA',
    },
    orange: {
      100: '#FF7D1B',
    },
    red: {
      50: '#e35256',
      100: '#D60D31',
    },
    gray: {
      50: '#E8EFF6',
      60: '#ced4da',
      100: '#7B8191',
    },
    brand: {
      50: '#fafafa',
      100: '#f2f2f2',
      150: '#e7e7e7',
      200: '#e1e1e1',
      300: '#d3d3d3',
      350: '#c1c1c1',
      400: '#c4c4c4',
      500: '#b5b5b5',
      600: '#a6a6a6',
      700: '#8c8c8c',
      750: '#56565C',
      800: '#333333',
    },
    white: '#fff',
  },
  fontSize: {
    xs: ['0.75rem', { lineHeight: '1rem' }],
    sm: ['0.875rem', { lineHeight: '1.25rem' }],
    base: ['1rem', { lineHeight: '1.5rem' }],
    lg: ['1.125rem', { lineHeight: '1.75rem' }],
    xl: ['1.25rem', { lineHeight: '1.75rem' }],
    '2xl': ['1.5rem', { lineHeight: '2rem' }],
    '3xl': ['1.65rem', { lineHeight: '2.25rem' }],
    '4xl': ['2.5rem', { lineHeight: '2.5rem' }],
    '5xl': ['3rem', { lineHeight: '1' }],
    '6xl': ['3.75rem', { lineHeight: '1' }],
    '7xl': ['4.5rem', { lineHeight: '1' }],
    '8xl': ['6rem', { lineHeight: '1' }],
    '9xl': ['8rem', { lineHeight: '1' }],
  },
  fontWeight: {
    light: '300',
    normal: '400',
    medium: '500',
    bold: '700',
  },
  spacing: {
    px: '1px',
    0: '0px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.75rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
},
  components: {
    Editable: {
      variants: {
        signing: {
          preview: {
            bg: 'rgba(255, 255, 204, .9)',
            color: 'brand.800',
            borderColor: 'brand.300',
            borderWidth: 1,
            borderRadius: 0,
            whiteSpace: 'nowrap',
            lineHeight: 1,
            p: 1,
            _hover: {
              bg: 'brand.300',
            },
          },
          input: {
            bg: 'rgba(255, 255, 204, .9)',
            color: 'brand.800',
            borderColor: 'brand.300',
            borderWidth: 1,
            borderRadius: 0,
            minWidth: '200px',
            zIndex: 1000,
            whiteSpace: 'nowrap',
            lineHeight: 1,
            p: 1,
            _hover: {
              bg: 'brand.300',
            },
          },
        },
      },
    },
    Link: {
      variants: {
        primary: {
          minW: 140,
          fontWeight: '500',
          fontFamily: 'facto',
          h: '35px',
          color: 'gray.50',
          backgroundColor: 'indigo.100',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          _hover: {
            backgroundImage: `linear-gradient(90deg,#00e7f0,#00e7f0 35%,indigo.100 65%,indigo.100)`,
            backgroundPosition: '50%',
          },
          _disabled: {
            opacity: 0.8
          }
        },
      }
    },
    Button: {
      variants: {
        brand: {
          minW: 140,
          fontWeight: '500',
          fontFamily: 'facto',
          h: '35px',
          color: 'indigo.100',
          backgroundColor: 'indigo.100',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem', //'spacing.10',
          paddingRight: '2.5rem', //'spacing.10',
          _hover: {
            backgroundImage: `linear-gradient(90deg,#00e7f0,#00e7f0 35%,indigo.100 65%,indigo.100)`,
            backgroundPosition: '50%',
          },
        },
        primary: {
          minW: 140,
          fontWeight: '500',
          fontFamily: 'facto',
          h: '35px',
          color: 'gray.50',
          backgroundColor: 'indigo.100',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem', //'spacing.10',
          paddingRight: '2.5rem', //'spacing.10',
          _hover: {
            backgroundImage: `linear-gradient(90deg,#00e7f0,#00e7f0 35%,indigo.100 65%,indigo.100)`,
            backgroundPosition: '50%',
          },
          _disabled: {
            opacity: 0.8
          }
        },
        green: {
          minW: 140,
          fontWeight: '500',
          fontFamily: 'facto',
          h: '35px',
          color: 'indigo.100',
          backgroundColor: 'teal.100',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem',
          paddingRight: '2.5rem',
          border: '1px solid',
          borderColor: 'indigo.100',
          _hover: {
            backgroundImage: `linear-gradient(90deg,#00e7f0,#00e7f0 35%,indigo.100 65%,indigo.100)`,
            backgroundPosition: '50%',
          },
        },
        success: {
          minW: 140,
          fontWeight: '500',
          fontFamily: 'facto',
          h: '35px',
          color: 'indigo.100',
          backgroundColor: 'teal.100',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem', //'spacing.10',
          paddingRight: '2.5rem', //'spacing.10',
          _hover: {
            backgroundImage: `linear-gradient(90deg,#00e7f0,#00e7f0 35%,indigo.100 65%,indigo.100)`,
            backgroundPosition: '50%',
          },
        },
        outline: {
          minW: 140,
          fontWeight: 'medium',
          fontFamily: 'facto',
          h: '35px',
          color: '#0206A8 !important', // chakra bug, cannot use here theme color
          borderColor: 'indigo.100',
          backgroundColor: 'white',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem', //'spacing.10',
          paddingRight: '2.5rem', //'spacing.10',
          _hover: {
            borderColor: '#00e7f0'
          },
        },
        danger: {
          minW: 140,
          fontWeight: 'medium',
          fontFamily: 'facto',
          h: '35px',
          color: 'red.100',
          borderColor: 'red.100',
          borderStyle: 'solid',
          borderWidth: '1px',
          backgroundColor: 'white',
          fontSize: 'fontSize.lg',
          borderRadius: '9999px',
          backgroundSize: '300%',
          paddingLeft: '2.5rem', //'spacing.10',
          paddingRight: '2.5rem', //'spacing.10',
          _hover: {
            borderColor: 'red.50'
          },
          _disabled: {
            opacity: 0.8
          }
        },
        evergray: (props: ButtonProps) => ({
          bg: props.isActive ? 'green.400' : 'brand.100',
          color: 'brand.800',
          _first: {
            borderLeftColor: 'brand.300',
            borderLeftWidth: 1,
          },
          _last: {
            borderRightColor: 'brand.300',
            borderRightWidth: 1,
          },
          _even: {
            borderColor: 'brand.300',
            borderWidth: 1,
          },
          _odd: {
            borderTopColor: 'brand.300',
            borderBottomColor: 'brand.300',
            borderTopWidth: 1,
            borderBottomWidth: 1,
          },
          _hover: {
            bg: 'brand.300',
          },
        }),
      },
    },
  },
})
