import { CCFGButton, Flex, Text } from '../../primitives'
import { useResendConfirmationEmail } from '../UserSettings/use-user-settings'


const BG_COLOR = 'var(--chakra-colors-yellow-100)'
const TEXT_COLOR = 'var(--chakra-colors-navy-100)'

const EmailConfirmationBanner = () => {
  const resendConfirmationEmail = useResendConfirmationEmail()

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      backgroundColor={BG_COLOR}
      height={['100px', '35px']}
      p={['10px', '0']}
      flexDirection={['column', 'row']}
    >
      <Text fontSize={'sm'} textAlign={['center', 'left']} color={TEXT_COLOR}>
        We've sent a confirmation email to your inbox. Please verify your account before using Xodo
        Sign.
      </Text>
      <CCFGButton
        data-testid="resend-confirmation-email"
        backgroundColor={BG_COLOR}
        color={TEXT_COLOR}
        borderColor={TEXT_COLOR}
        fontWeight="normal"
        fontSize={'12px !important'}
        m={'10px !important'}
        maxHeight={'29px'}
        onClick={() => {
          resendConfirmationEmail.mutate()
        }}
        isDisabled={resendConfirmationEmail.isSuccess}
        p={'5px 12px'}
        minWidth={resendConfirmationEmail.isSuccess ? 'fit-content' : 'inherit'}
      >
        {resendConfirmationEmail.isSuccess ? 'Sent' : 'Resend Confirmation'}
      </CCFGButton>
    </Flex>
  )
}

export default EmailConfirmationBanner
