import { ModalCloseButton as Base, CloseButtonProps as Props } from '@chakra-ui/react'

export interface BoxProps extends Props {
  /**
   *
   * testId is used in SAT/e2e as element selector
   *
   * @type {string}
   * @memberof BoxProps
   */
  testId?: string
}

export function ModalCloseButton(props: BoxProps) {
  return (
    <Base
      {...props}
      background="none"
      color="gray.100"
      _hover={{ background: 'none' }}
      _focus={{ bg: 'none' }}
      _active={{ bg: 'none' }}
    />
  )
}
export default ModalCloseButton
