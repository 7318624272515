import { BoxProps, Checkbox as Base, CheckboxProps as Props } from '@chakra-ui/react'
import { forwardRef } from 'react'

const BASE_CHECKBOX_BORDER_COLOR = 'gray.60'
const BASE_CHECKBOX_BG_COLOR = 'white'

export interface ExtendedCheckboxProps extends Props {
  testId?: string
  preApp?: boolean
  tooltip?: string
}

export type CheckboxProps = ExtendedCheckboxProps &
  React.RefAttributes<HTMLInputElement> &
  Omit<BoxProps, 'onChange' | 'defaultChecked'>

export const Checkbox: React.FC<CheckboxProps> = forwardRef(
  ({ testId, tooltip, size = 'md', value, ...props }: CheckboxProps, ref) => {
    const isChecked = !!Boolean(value)

    return (
      <Base
        ref={ref}
        data-testid={testId}
        borderWidth={0}
        bg={BASE_CHECKBOX_BG_COLOR}
        borderColor={BASE_CHECKBOX_BORDER_COLOR}
        size={size}
        isChecked={isChecked}
        value={value}
        {...props}
      />
    )
  },
)

export default Checkbox
