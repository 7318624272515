import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_LANGUAGE } from '../../../constants'
import { Card, Flex, Grid, Stack, Text } from '../../../primitives'
import { BaseInputWrapper, Control } from '../../../primitives/Control/Control'
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export function ContactBasicInfo({ phoneNumber }: { phoneNumber?: string }) {
  const [error, setError] = useState('')

  const { control, setValue } = useForm()

  useEffect(() => {
    setValue('phone_number', phoneNumber || '')
  }, [phoneNumber, setValue])


  return (
    <Card
      renderHeader={
        <Flex justifyContent="space-between" as="header">
          <Text fontFamily="pn-sb" as="span">
            Basic Information
          </Text>
        </Flex>
      }
    >
      <Flex as="fieldset" direction={['column', 'column', 'row', 'row']}>
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={'20px'}
          width={['100%', '100%', '50%', '50%']}
          padding="20px"
          borderRight="1px solid #e8e8e8"
        >
          <Control
            validationOptions={{
              minLength: {
                value: 3,
                message: 'needs more then 2 characters',
              },
              maxLength: {
                value: 75,
                message: 'can not exceed 75 characters',
              },
            }}
            type="text"
            isRequired
            label="First Name"
            name="first_name"
          />
          <Control type="text" label="Last Name" name="last_name" />
          <Control
            validationOptions={{
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: 'is not valid',
              },
            }}
            type="text"
            isRequired
            label="Email Address"
            name="email_address"
          />
          <BaseInputWrapper
            label={'Phone number'}
            name={'phone_number'}
            error={error ? { type: '', message: 'nok' } : undefined}
            messages={error ? [error] : []}
            isRequired={false}
          >
            <Controller
              name="phone_number"
              control={control}
              defaultValue={phoneNumber}
              render={({ field }) => (
                <PhoneInputWithCountry
                  {...field}
                  defaultCountry="US"
                  addInternationalOption={false}
                  countryOptionsOrder={['US', 'DE', 'GB', 'FR', 'ID', 'AT']}
                  placeholder="Enter phone number"
                  onChange={(value: string) => {
                    if (!value) return
                    if (value && !isPossiblePhoneNumber(value)) {
                      setValue('phone_number', value)
                      setError('is not valid')
                    } else {
                      setError('')
                    }
                  }}
                />
              )}
            />
          </BaseInputWrapper>
          <Control type="text" label="Company Name" name="company_name" />
          <Control type="text" label="Website URL" name="website_url" />
        </Grid>
        <Stack spacing="20px" padding="20px" width={['100%', '100%', '50%', '50%']}>
          <Control
            options={DEFAULT_LANGUAGE}
            type="autocomplete-select"
            label="Language"
            name="default_language"
          />
          <Control type="textarea" label="Notes" name="notes" />
        </Stack>
      </Flex>
    </Card>
  )
}
