import {
  Flex,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Skeleton,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  EvrCaretDown,
  EvrCaretUp,
  EvrCode,
  EvrCopy,
  EvrCreditCard,
  EvrCube,
  EvrFeather,
  EvrGroupWork,
  EvrHammer,
  EvrLock,
  EvrMenu,
  EvrPieChart,
  EvrPowerSwitch,
  EvrSettings,
  EvrStar,
  EvrTrash2,
  EvrUsers,
  EvrCompare,
  Text,
  Box,
} from '../../primitives'
import Image from '../../primitives/Image/Image'

const GRAY_50 = 'var(--chakra-colors-gray-50)'
const GRAY_60 = 'var(--chakra-colors-gray-60)'
const NAVY_100 = 'var(--chakra-colors-navy-100)'

export const DropdownMenu = ({ userName, isOpen, onToggle }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onToggle()
  }

  // when this feature is ran from "react-entry.php" with react router as provider,
  // we need to use ReactRouterLink in mobile menu for User
  // however when this component is run as old non routed feature,
  // there wont be Router Provider wrapping it, so it will throw error when using ReactRouterLink
  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        onClick={handleClick}
        width="100%"
        height="40px"
        borderRadius="0px"
        bg="navy.100"
        color="white"
        data-testid="business-actions-dropdown"
      >
        <Flex justifyContent={'center'} alignItems="center">
          <Image src="https://assets.eversign.com/images/xodosign-icon-64.png" width="30px" />
          <Text fontSize={'sm'} fontWeight="medium" mx="10px !important">
            {userName}
          </Text>
          {isOpen ? <EvrCaretUp mt="6px" /> : <EvrCaretDown mt="6px" />}
        </Flex>
      </MenuButton>

      <MenuList
        borderRadius={'0px'}
        fontSize={'14px'}
        color="indigo.100"
        py="0px"
        fontFamily={'facto'}
        fontWeight={400}
        data-testid="dropdown-business-actions-list-container"
        minWidth="180px"
        width={['100VW', '210px']}
        mt={['0px', '6px']}
      >
        <MenuItem
          as="a"
          href="/select"
          color="indigo.100"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Exit Business
        </MenuItem>
        <MenuItem
          color="navy.100"
          borderBottom={'1px solid'}
          borderColor="brand.700"
          bgColor="gray.50"
          height="37px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          <Text as="span" fontWeight={500} fontSize={'sm'} noOfLines={1} lineHeight="normal">
            Logged in as:{' '}
            <Text
              as="span"
              fontWeight={400}
              color={'brand.800'}
              fontFamily="facto"
              fontSize={'sm'}
              data-testid={'username'}
            >
              {userName}
            </Text>
          </Text>
        </MenuItem>
        {reactRouterEntryNode ? (
          <ChakraLink
            as={ReactRouterLink}
            to="/user"
            href="/user"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
            display="flex"
            alignItems={'center'}
          >
            <EvrHammer color="brand.800" h={5} w={5} ml="3" mr="3" />
            <Text as="span" fontSize={'14px'}>
              User Settings
            </Text>
          </ChakraLink>
        ) : (
          <MenuItem
            icon={<EvrHammer color="brand.800" h={5} w={5} />}
            as="a"
            href="/user"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
          >
            User Settings
          </MenuItem>
        )}
        {reactRouterEntryNode ? (
          <ChakraLink
            as={ReactRouterLink}
            to="/signature"
            href="/signature"
            bgColor={`${GRAY_50} !important`}
            height="37px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
            display="flex"
            alignItems={'center'}
          >
            <EvrFeather color="brand.800" h={5} w={5} ml="3" mr="3" />
            <Text as="span" fontSize={'14px'}>
              Signatures
            </Text>
          </ChakraLink>
        ) : (
          <MenuItem
            icon={<EvrFeather color="brand.800" h={5} w={5} />}
            as="a"
            href="/signature"
            bgColor={`${GRAY_50} !important`}
            height="40px"
            _hover={{
              bg: `${GRAY_60} !important`,
              outline: 'none',
              shadow: 'none',
            }}
          >
            Signatures
          </MenuItem>
        )}
        <MenuItem
          icon={<EvrLock color="brand.800" h={5} w={5} />}
          as="a"
          href="/account/edit"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Account
        </MenuItem>
        <MenuItem
          icon={<EvrStar color="brand.800" h={5} w={5} />}
          as="a"
          href="/subscription"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Subscription
        </MenuItem>
        <MenuItem
          icon={<EvrCreditCard color="brand.800" h={5} w={5} />}
          as="a"
          href="/billing"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Billing
        </MenuItem>
        <MenuItem
          icon={<EvrCode color="brand.800" h={5} w={5} />}
          as="a"
          href="/developer"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Developer
        </MenuItem>
        <MenuItem
          icon={<EvrCube color="brand.800" h={5} w={5} />}
          as="a"
          href="/authorizations"
          height="40px"
          _hover={{
            bg: 'gray.60',
            outline: 'none',
            shadow: 'none',
          }}
        >
          Connected Apps
        </MenuItem>
        <MenuItem
          icon={<EvrPowerSwitch color="brand.800" h={5} w={5} />}
          as="a"
          href="/logout"
          bgColor={`${GRAY_50} !important`}
          height="40px"
          _hover={{
            bg: `${GRAY_60} !important`,
            outline: 'none',
            shadow: 'none',
          }}
        >
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const MobileMenu = ({ userName }) => {
  const { isOpen, onToggle } = useDisclosure()
  const [isScrollDisabled, setIsScrollDisabled] = useState(false)
  const reactRouterEntryNode = document.getElementById('react-v2-mounting-point')

  const handleOpen = () => {
    document.body.style.overflow = 'hidden'
    setIsScrollDisabled(true)
  }

  const handleClose = () => {
    if (isOpen) {
      onToggle()
    }
  }

  const handleToggle = () => {
    onToggle()
    document.body.style.overflow = 'hidden'
    setIsScrollDisabled(true)
  }
  return (
    <Box>
      <Menu onOpen={handleOpen} onClose={handleClose}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<EvrMenu color="white" h={5} w={5} />}
          bgColor="navy.100"
          color="white"
          position="relative"
          margin="20px !important"
          maxWidth="37px"
          height={'37px'}
          data-testid="app-header-mobile-menu"
        />
        <MenuList
          style={{ width: '100vw' }}
          borderRadius={'0px'}
          fontSize={'14px'}
          color="indigo.100"
          height={'100vh'}
          width="100%"
          bg={'navy.100'}
        >
          <Skeleton
            isLoaded={userName}
            borderRadius="md"
            mx="120px"
            startColor="navy.70"
            endColor="navy.60"
          >
            <DropdownMenu userName={userName} isOpen={isOpen} onToggle={handleToggle} />
          </Skeleton>
          <Grid
            data-testid=" mobile-menu-list-container"
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            rowGap={2.5}
            columnGap={2}
            p="10px"
          >
            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/dashboard"
                href="/dashboard"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrPieChart w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Dashboard{' '}
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/dashboard"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrPieChart w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Dashboard{' '}
                </Text>
              </MenuItem>
            )}

            <MenuItem
              bg={`${GRAY_50} !important`}
              borderRadius={'3px'}
              padding="15px"
              as="a"
              href="/documents"
              height={'74px'}
              justifyContent="flex-end"
              flexDir={'column'}
              _active={{ bg: `${NAVY_100} !important` }}
            >
              <EvrCopy w={'20px'} h={'20px'} mb={'4px'} />
              <Text fontSize={'sm'} fontWeight="medium">
                Documents{' '}
              </Text>
            </MenuItem>
            <MenuItem
              bg={`${GRAY_50} !important`}
              borderRadius={'3px'}
              padding="15px"
              as="a"
              href="/templates"
              height={'74px'}
              justifyContent="flex-end"
              flexDir={'column'}
              _active={{ bg: `${NAVY_100} !important` }}
            >
              <EvrCompare w={'20px'} h={'20px'} mb={'4px'} />
              <Text fontSize={'sm'} fontWeight="medium">
                Templates{' '}
              </Text>
            </MenuItem>

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/contacts"
                href="/contacts"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrGroupWork w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Contacts{' '}
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/contacts"
                justifyContent="flex-end"
                flexDir={'column'}
                height="74px"
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrGroupWork w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Contacts{' '}
                </Text>
              </MenuItem>
            )}

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/team"
                href="/team"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrUsers w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Team
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/team"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrUsers w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Team
                </Text>
              </MenuItem>
            )}

            {reactRouterEntryNode ? (
              <ChakraLink
                as={ReactRouterLink}
                to="/trash"
                href="/trash"
                height="74px"
                padding="15px"
                borderRadius={'3px'}
                bg={`${GRAY_50} !important`}
              >
                <Flex justify={'center'} direction="column" alignItems={'center'}>
                  <EvrTrash2 w={'20px'} h={'20px'} mb={'4px'} />
                  <Text fontSize={'sm'} fontWeight="medium">
                    Trash
                  </Text>
                </Flex>
              </ChakraLink>
            ) : (
              <MenuItem
                bg={`${GRAY_50} !important`}
                borderRadius={'3px'}
                padding="15px"
                as="a"
                href="/trash"
                height={'74px'}
                justifyContent="flex-end"
                flexDir={'column'}
                _active={{ bg: `${NAVY_100} !important` }}
              >
                <EvrTrash2 w={'20px'} h={'20px'} mb={'4px'} />
                <Text fontSize={'sm'} fontWeight="medium">
                  Trash
                </Text>
              </MenuItem>
            )}
            <MenuItem
              bg={`${GRAY_50} !important`}
              borderRadius={'3px'}
              padding="15px"
              as="a"
              href="/settings"
              height={'74px'}
              justifySelf={'center'}
              flexDir="column"
              gridColumn={'span 2'}
              maxW="50%"
              justifyContent="flex-end"
              _active={{ bg: `${NAVY_100} !important` }}
            >
              <EvrSettings w={'20px'} h={'20px'} mb={'4px'} />
              <Text fontSize={'sm'} fontWeight="medium">
                Business Settings{' '}
              </Text>
            </MenuItem>
          </Grid>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default MobileMenu
