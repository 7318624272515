import { Flex as Base, FlexProps as Props } from '@chakra-ui/react'

export interface FlexProps extends Props {
  testId?: string
}

export function Flex({ testId, ...props }: FlexProps) {
  return <Base data-testid={testId} {...props} />
}

export default Flex
