import { Icon, IconProps } from '@chakra-ui/react'

export const EvrMenu = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 6h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M17.5 11h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M17.5 16h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
  </Icon>
)
