import React from 'react'
import { IconButton as Base, IconButtonProps as Props } from '@chakra-ui/react'

export interface IconButtonProps extends Props {
  testId?: string
}

export function IconButton({testId, ...props}: IconButtonProps) {
  return <Base data-testid={testId} {...props} />
}

export default IconButton
