import { Button as Base, ButtonProps as Props } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const LIST_BUTTON_BG_COLOR_SECONDARY = 'gray.50'
export const LIST_BUTTON_BG_COLOR_HOVER_SECONDARY = 'gray.50'

export const LIST_BUTTON_BORDER_COLOR_PRIMARY = 'gray.60'
const BORDER_COLOR_SECONDARY = 'gray.50'

export interface ListButtonProps extends Props {
  /**
   * testId is used in SAT/e2e as element selector
   *
   * @requires
   * @type {string}
   * @memberof ButtonProps
   */
  testId: string
  tooltip?: string
}

export const ListButton = forwardRef(({ testId, ...props }: ListButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <Base
    ref={ref}
    data-testid={testId}
    loadingText="...Loading"
    // used for grouped button
    // don't work if it gets wrapped
    _first={{
      borderLeftWidth: 1,
    }}
    _hover={{
      bg: LIST_BUTTON_BG_COLOR_HOVER_SECONDARY,
      borderColor: BORDER_COLOR_SECONDARY,
    }}
    _focus={{
      boxShadow: 'none',
    }}
    fontFamily="pn-r"
    color={'navy.100'}
    bg={LIST_BUTTON_BG_COLOR_SECONDARY}
    borderColor={LIST_BUTTON_BORDER_COLOR_PRIMARY}
    borderWidth={1}
    borderStyle={'solid'}
    borderLeftWidth={0}
    borderRadius={0}
    h={'35px'}
    {...props}
  />
))
