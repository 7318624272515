import { Trans } from 'react-i18next'
import { Stack, FormErrorMessage } from '../'
import camelcase from 'camelcase'
export interface ErrorListProps {
  messages: string[]
  name: string
  label: string
}

export function ErrorWrapper({ messages, name, label }: ErrorListProps) {
  if (messages.length > 1) {
    return (
      <Stack my={2}>
        {messages.map((message) => (
          <FormErrorMessage
            testId={`error-${name}-${camelcase(message)}`}
            key={`${message}-${name}`}
          >
            {label} <Trans i18nKey={`control.${message}`} >{message}</Trans>
          </FormErrorMessage>
        ))}
      </Stack>
    )
  }

  return (
    <>
      {messages.map((message) => (
        <FormErrorMessage testId={`error-${name}-${camelcase(message)}`} key={`${message}-${name}`}>
          {label} <Trans i18nKey={`control.${message}`}>{message}</Trans>
        </FormErrorMessage>
      ))}
    </>
  )
}
