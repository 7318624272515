import { Icon, IconProps } from '@chakra-ui/react'

export const EvrRegister = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.5 20h-13c-0.827 0-1.5-0.673-1.5-1.5v-16c0-0.827 0.673-1.5 1.5-1.5h13c0.827 0 1.5 0.673 1.5 1.5v2c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5v16c0 0.276 0.224 0.5 0.5 0.5h13c0.276 0 0.5-0.224 0.5-0.5v-4c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v4c0 0.827-0.673 1.5-1.5 1.5z"
      fill="currentColor"
    ></path>
    <path
      d="M10.5 5h-7c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h7c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M12.5 7h-9c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h9c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M11.5 9h-8c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h8c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M8.5 11h-5c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h5c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
    <path
      d="M8.5 17c-0.134 0-0.265-0.054-0.36-0.153-0.124-0.128-0.17-0.313-0.121-0.484l1-3.5c0.023-0.082 0.067-0.156 0.127-0.216l7.5-7.5c0.195-0.195 0.512-0.195 0.707 0l2.5 2.5c0.194 0.194 0.195 0.508 0.003 0.704l-7.5 7.641c-0.065 0.066-0.147 0.113-0.238 0.135l-3.5 0.859c-0.039 0.010-0.079 0.014-0.119 0.014zM9.945 13.262l-0.728 2.547 2.525-0.62 7.053-7.186-1.796-1.796-7.055 7.055z"
      fill="currentColor"
    ></path>
    <path
      d="M6.5 17h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"
      fill="currentColor"
    ></path>
  </Icon>
)
