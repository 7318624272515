import { Input as Base, InputProps as Props } from '@chakra-ui/react'
import React from 'react'

export interface InputProps extends Props {
  testId: string
}

//@ts-ignore
function Input({ testId, isChecked, ...props }: InputProps, ref: React.Ref<HTMLInputElement>) {
  return (
    <Base
      data-testid={testId}
      borderWidth={1}
      borderColor="gray.60"
      height={'35px'}
      px={1}
      fontSize={'text.base !important'}
      color={'navy.100'}
      {...props}
      borderRadius={2}
      ref={ref}
    />
  )
}
export default React.forwardRef(Input)
