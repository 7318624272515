import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import documentSigner from './translations/documentSigner.json'
import translationEN from './translations/en.json'

const resources = {
  en: {
    translation: translationEN,
    documentSigner: documentSigner,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en'],
    resources,
    debug: false,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'b', 'u', 'strong', 'i', 'p'],
    },
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'translation',
  })

export default i18n
