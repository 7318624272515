import { FormErrorMessage as Base, BoxProps as Props } from '@chakra-ui/react'

interface FormErrorMessageProps extends Props {
  /**
   * testId is used in SAT/e2e/unit test as element selector
   *
   * @requires
   * @type {string}
   * @memberof ButtonProps
   */
  testId: string
}

export function FormErrorMessage({testId,...props}: FormErrorMessageProps) {
  return <Base data-testid={testId} {...props} />
}
export default FormErrorMessage
