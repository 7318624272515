import React, { forwardRef } from 'react'
import { Tooltip as Base, TooltipProps as Props } from '@chakra-ui/react'

export type FlexProps = Props
export const CustomTooltip: React.FC<Props> = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  return (
    <Base
      {...props}
      hasArrow={false}
      placement="top"
      bg="teal.10"
      boxShadow="0 2px 8px 0 rgba(75, 75, 87, .4)"
      borderRadius="10px"
      aria-label="tooltip"
      zIndex={1000000}
      color="navy.100"
      fontSize="sm"
      padding="4"
      ref={ref}
    />
  )
})

export default CustomTooltip
