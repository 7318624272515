import { Button as Base, ButtonProps as Props } from '@chakra-ui/react'
import { useAppContext } from '../../Provider/App.Provider'
export const BaseButton = Base
export interface ButtonProps extends Props {
  /**
   * testId is used in SAT/e2e as element selector
   *
   * @requires
   * @type {string}
   * @memberof ButtonProps
   */
  testId?: string
}

export function Button({ testId, ...props }: ButtonProps) {
  return <Base data-testid={testId} loadingText=" ... Loading " {...props} />
}

export function PrimaryButton({ testId, ...props }: ButtonProps) {
  let whenIsDisabled = {
    _hover: {
      opacity: 0.7
    },
  }
  if (props.isDisabled) {
    whenIsDisabled = {
      _hover: {
        opacity: 0.7,
        //@ts-ignore
        color: 'navy.100',
      },
    }
  }
  return (
    <Button
      {...whenIsDisabled}
      testId={testId}
      variant="primary"
      {...props}
    />
  )
}
export function SuccessButton({ testId, ...props }: ButtonProps) {
  const whenIsDisabled = {
    _hover: {
      opacity: 0.6,
    },
  }

  return <Button {...whenIsDisabled} testId={testId} variant="success" {...props} />
}
export function SecondaryButton({ testId, ...props }: ButtonProps) {
  const whenIsDisabled = {
    _hover: {
      opacity: 0.6,
    },
  }

  return <Button {...whenIsDisabled} testId={testId} variant="outline" {...props} />
}

export function DangerButton({ testId, ...props }: ButtonProps) {
  const whenIsDisabled = {
    _hover: {
      opacity: 0.6,
    },
  }

  return <Button {...whenIsDisabled} testId={testId} variant="danger" {...props} />
}

interface CCFGButtonProps extends ButtonProps {
  isSecondary?: boolean
}

// 
// Set styling from business settings button
// 
export function CCFGButton({ testId, isSecondary, onClick, ...props }: CCFGButtonProps) {
  const app = useAppContext()
  const whenIsDisabled = {
    _hover: {
      opacity: 0.6,
    },
  }

  // customer defined styles: we will only support it on signing pages, not in-app
  const bg = isSecondary ? app.phpFlags.ccfg_button_secondary : app.phpFlags.ccfg_button_primary

  return (
    <Button
      {...whenIsDisabled}
      testId={testId}
      variant="outline"
      onClick={onClick}
/*       
      bg={`#${bg} !important`}
      color={'#FFF4F4 !important'}
      fontSize="15px !important"
      px="16px"
      h={"37px"}
      py="11px"
      fontWeight={"normal"}
      lineHeight="15px !important" 
*/
      {...props}
    />
  )
}

export default Button
