import { COUNTRY_LIST } from '../../../constants'
import { Card, Flex, Grid, Text } from '../../../primitives'
import { Control } from '../../../primitives/Control/Control'

export function ContactAdress() {
  return (
    <Card
      marginTop="20px"
      renderHeader={
        <Flex justifyContent="space-between">
          <Text fontFamily="pn-sb" as="span">
            Address
          </Text>
        </Flex>
      }
    >
      <Flex as="fieldset" direction={['column', 'column', 'row', 'row']}>
        <Grid
          templateColumns="repeat(1, 1fr)"
          gap={'20px'}
          width={['100%', '100%', '50%', '50%']}
          padding="20px"
          borderRight="1px solid #e8e8e8"
        >
          <Control type="text" label="Address (Line 1)" name="address_1" />
          <Control type="text" label="Address (Line 2)" name="address_2" />
        </Grid>
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'20px'}
          width={['100%', '100%', '50%', '50%']}
          padding="20px"
        >
          <Control type="text" label="Postal Code" name="zip_code" />
          <Control type="text" label="State" name="state" />
          <Control type="text" label="City" name="city" />
          <Control
            options={COUNTRY_LIST}
            type="autocomplete-select"
            label="Country"
            name="country_code"
          />
        </Grid>
      </Flex>
    </Card>
  )
}
