import { CardHeaderSecondary } from '.'
import { Flex, FlexProps } from '..'
import { CardHeader } from './CardHeader'

export const CARD_BORDER_COLOR = 'gray.50'
const CARD_FONT_COLOR = 'navy.100'
export interface CardProps extends FlexProps {
  renderHeader?: React.ReactNode
  bodyProps?: FlexProps
}

export function Card({ renderHeader,bodyProps, children, ...flexProps }: React.PropsWithChildren<CardProps>) {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      borderColor={'gray.50'}
      borderWidth={1}
      borderRadius={3}
      borderStyle="solid"
      as="section"
      overflow="visible"
      {...flexProps}
    >
      {renderHeader && <CardHeader>{renderHeader}</CardHeader>}
      <Flex bg="#fff" color={CARD_FONT_COLOR} flexDirection="column" {...bodyProps}>
        {children}
      </Flex>
    </Flex>
  )
}

const CARD_SECONDARY_BG_COLOR = 'white'

export function CardSecondary({
  renderHeader,
  children,
  ...flexProps
}: React.PropsWithChildren<CardProps>) {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      borderColor={'gray.50'}
      borderWidth={1}
      borderRadius={3}
      borderStyle="solid"
      as="section"
      overflow="hidden"
      {...flexProps}
    >
      {renderHeader && <CardHeaderSecondary>{renderHeader}</CardHeaderSecondary>}
      <Flex bg={CARD_SECONDARY_BG_COLOR} color={CARD_FONT_COLOR} flexDirection="column">
        {children}
      </Flex>
    </Flex>
  )
}
