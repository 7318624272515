import React from 'react'
import { Stack as Base, StackProps as Props } from '@chakra-ui/react'

export interface StackProps extends Props {
  /**
   *
   * testId is used in SAT/e2e as element selector
   *
   * @type {string}
   * @memberof StackProps
   */
  testId?: string
}

export function Stack({ testId = 'section', ...props }: StackProps) {
  return <Base data-testid={testId} {...props} />
}

export default Stack
