import React from 'react'
import { Flex, FlexProps } from '../../primitives'

interface PageProps extends FlexProps {
  renderHeader: React.ReactNode
}

export function Page({
  children,
  renderHeader,
  as,
  onSubmit,
  ...props
}: React.PropsWithChildren<PageProps>) {
  return (
    <Flex justifyContent="center" flexDirection="column" as={as} onSubmit={onSubmit}>
      {renderHeader}
      <Flex mx={'20px'} justifyContent="center" flexDirection="column" mb={'40px'} {...props}>
        {children}
      </Flex>
    </Flex>
  )
}

export default Page
