import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'
import { SomethingBadHappened } from '../primitives/Error/SomethingBadHappened'
import { createContext } from '../uitls/reactHelpers'
import GlobalCssProvider from './GlobalCssProvider'

export interface PHPFlags extends Record<string, string> {
  business_identifier: string
  business_name: string
  ccfg_button_primary: string
  ccfg_button_secondary: string
  ccfg_main_primary: string
  ccfg_main_secondary: string
  current_page: string
  'data-ajax-get-file': string
  'data-ajax-get-page': string
  default_language: string
  document_list_default_tab: string
  enable_one_click_signature_fields: string
  hide_eversign_branding_signing_page: string
  html_title: string
  portal_name: string
  require_signatures_separately: string
  signing_steps_include_optional_fields: string
  subscription_id: string
}
interface AppContext {
  businessId: number
  accessKey: string

  phpFlags: PHPFlags

  EVERSIGN_API_URL: string
  BASE_URL: string
}

export const [AppProvider, useAppContext] = createContext<AppContext>({
  strict: true,
  name: 'AppContext',
  errorMessage:
    'useAppContext: `context` is undefined. Seems you forgot to wrap components in `<App />`',
})

function getHiddenKeyValuePairs(defaultFlags?: Record<string, string>): Record<string, string> {
  if (defaultFlags) {
    return defaultFlags
  }

  const keys = document.querySelectorAll<HTMLInputElement>('input[type=hidden]')

  return Array.from(keys).reduce((acc: Record<string, string>, element) => {
    if (element.value.length > 0) {
      acc[element.name] = element.value
    }
    return acc
  }, {})
}

export function getAppConfig(): Window['EVERSIGN_CONFIG'] {
  let config: Window['EVERSIGN_CONFIG'] = {
    ...window.EVERSIGN_CONFIG,
  }
  if (!window.EVERSIGN_CONFIG) {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error('EVERSIGN_CONFIG is not set')
    } else {
      config = {
        ...config,
        EVERSIGN_API_URL: 'https://eversign-api.mgm.apilayer.localhost',
        BASE_URL: 'eversign.localhost',
      }
    }
  }
  return config
}

interface AppProps {
  enabledPHPFlags?: boolean
  defaultPhpFlags?: Record<string, string>
}

const queryClient = new QueryClient()

export const App = ({
  children,
  defaultPhpFlags,
  enabledPHPFlags = true,
}: PropsWithChildren<AppProps>) => {
  const phpFlags = getHiddenKeyValuePairs(defaultPhpFlags)
  //   phpFlags.access_key//?
  if (enabledPHPFlags && !phpFlags.access_key) throw new Error('access_key not defined')
  if (enabledPHPFlags && !phpFlags.business_id) throw new Error('business_id not defined')

  const accessKey = phpFlags.access_key
  const businessId = Number(phpFlags.business_id)
  const config = getAppConfig()

  return (
    <AppProvider
      value={{
        businessId: Number(businessId),
        accessKey,
        EVERSIGN_API_URL: config.EVERSIGN_API_URL as string,
        BASE_URL: config.BASE_URL as string,
        //@ts-ignore
        phpFlags: phpFlags as PHPFlags,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={SomethingBadHappened}>
          <GlobalCssProvider>{children}</GlobalCssProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </AppProvider>
  )
}
